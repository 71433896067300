<template>
  <v-sheet class="pa-5" elevation="1">
    <div class="d-flex flex-row align-center justify-space-between px-10">
      <div class="d-flex flex-column mr-10">
        <v-avatar size="125">
          <img
            v-if="driver.imageURL === ''"
            src="~@/assets/avatar-male.png"
            alt="John"
          />
          <img v-else :src="driver.imageURL" alt="" />
        </v-avatar>
        <v-btn
          small
          class="mt-5 font-weight-medium"
          outlined
          rounded
          :color="driver.status === 'Active' ? 'success' : 'error'"
        >
          {{ driver.status }}
        </v-btn>
      </div>
      <div>
        <div>
          <div class="d-flex justify-end">
            <v-btn small color="primary" class="mr-5" @click="openEditDialog">Edit Profile</v-btn>
            <v-btn small color="green" dark @click="editAddressDialog = !editAddressDialog">Edit Address</v-btn>
          </div>
          <v-row no-gutters class="mt-5">
            <!-- <v-btn color="grey" text absolute top right @click="openEditDialog">
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
            <v-col cols="4" v-for="item in profileTitles" :key="item.id">
              <v-list-item two-line class="ml-10">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                    item.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-4"></v-divider>
        <div class="ml-10">
          <v-subheader class="font-weight-bold black--text"
            >Assigned Vans</v-subheader
          >
          <div v-if="driver.assignedVans.length > 0">
            <v-chip
              class="ma-2 px-5"
              color="primary"
              v-for="item in vans"
              :key="item"
            >
              {{ item }}
            </v-chip>
          </div>
          <div class="ml-10" v-else>
            -
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ 'Edit Driver' }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7" v-if="item.id === 'status'">
              <v-select
                outlined
                :items="statusItems"
                dense
                v-model="item.value"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7" v-else>
              <v-text-field outlined dense v-model="item.value"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                'Date of Birth'
              }}</v-subheader>
            </v-col>
            <v-col cols="12" md="7">
              <v-menu
                v-model="dobMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="dobDate = parseDate(dob)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="dobDate"
                  @input="dobMenu = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveEdit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editAddressDialog" max-width="550">
      <edit-address :toggleDialog="toggleDialog" :driver="driver" />
    </v-dialog>
  </v-sheet>
</template>

<script>
import moment from "moment";
import EditAddress from './EditAddress.vue';

export default {
  components: { EditAddress },
  props: {
    driver: {
      type: Object,
      required: true,
    },

    updateDriver: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    profileTitles: [],
    
    dialog: false,
    editAddressDialog: false,
    dobMenu: false,
    dobDate: '',
    statusItems: ["Active", "Disable"],
    toEdit: {},
    vans: [],
    formText: [
      {
        id: "firstName",
        title: "First Name",
        value: "",
      },
      {
        id: "lastName",
        title: "Last Name",
        value: "",
      },
      {
        id: "phoneNumber",
        title: "Phone Number",
        value: "",
      },
      {
        id: "licenseNumber",
        title: "License Number",
        value: "",
      },
      {
        id: "status",
        title: "Status",
        value: "",
      },
    ]
  }),

  watch: {
    dobDate(val) {
      this.dob = this.formatDate(this.dobDate);
    },
  },

  created() {
    this.vans = [...new Set(this.driver.assignedVans)]

    console.log(this.vans)

    this.profileTitles = [
      {
        id: 1,
        title: "Driver ID",
        value: `${this.driver.customId}`,
      },
      {
        id: 2,
        title: "Name",
        value: `${this.driver.fullName}`,
      },
      {
        id: 3,
        title: "Creation Date",
        value: `${this.getDate(this.driver.createdAt)}`,
      },
      {
        id: 4,
        title: "Email",
        value: `${this.driver.email}`,
      },
      {
        id: 5,
        title: "Phone Number",
        value: `${this.driver.phoneNumber}`,
      },
      {
        id: 6,
        title: "Adresse",
        value: `${this.driver.address.name}`,
      },
    ];
  },

  methods: {

    openEditAddressDialog() {
    },

    toggleDialog(payload) {
      this.profileTitles[5].value = payload.name
      this.editAddressDialog = !this.editAddressDialog
    },

    openEditDialog() {
      this.dialog = !this.dialog
      this.toEdit = this.driver
      this.formText[0].value = this.toEdit.firstName
      this.formText[1].value = this.toEdit.lastName
      this.formText[2].value = this.toEdit.phoneNumber
      this.formText[3].value = this.toEdit.licenseNumber
      this.formText[4].value = this.toEdit.status
      this.dob = this.toEdit.dob
      console.log(this.toEdit)
    },

    async saveEdit() {
      try {

        this.toEdit.firstName = this.formText[0].value
        this.toEdit.lastName = this.formText[1].value
        this.toEdit.phoneNumber = this.formText[2].value
        this.toEdit.licenseNumber = this.formText[3].value
        this.toEdit.status = this.formText[4].value
        this.toEdit.dob = this.dob

        

        let status = await this.$store.dispatch('driver/updateDriver', this.toEdit)

        if(status.code === 1) {
          this.driver = this.toEdit
          this.driver.fullName = `${this.driver.firstName} ${this.driver.lastName}`
          this.updateProfileInfo(this.driver)
          this.updateDriver(this.toEdit)
          this.dialog = false
          this.toEdit = {}
        } else {
          this.dialog = false
          this.toEdit = {}
        }
        

      } catch (error) {
        console.log(error)
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    updateProfileInfo(payload) {
      this.profileTitles = [
        {
          id: 1,
          title: "Driver ID",
          value: `${payload.customId}`,
        },
        {
          id: 2,
          title: "Name",
          value: `${payload.fullName}`,
        },
        {
          id: 3,
          title: "Creation Date",
          value: `${this.getDate(payload.createdAt)}`,
        },
        {
          id: 4,
          title: "Email",
          value: `${payload.email}`,
        },
        {
          id: 5,
          title: "Phone Number",
          value: `${payload.phoneNumber}`,
        },
        {
          id: 6,
          title: "Adresse",
          value: `${payload.address.name}`,
        },
      ]
    },

    getDate(date) {
      return moment(date)
        .locale("fr")
        .format("dddd D MMMM YYYY");
    },
  },
};
</script>

<style></style>
