<template>
  <v-card flat>
    <div
      style="height: 100vh"
      class="d-flex justify-center align-center"
      v-if="mapLoading"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card-text v-else>
      <Autocomplete :returnLatLng="returnLatLng" autocompleteType="Source" />
      <div class="map-container">
        <div id="map" style="height: 22em"></div>
      </div>
      <div class="picker-container mt-10">
        <v-text-field
          outlined
          dense
          label="Address"
          v-model="address"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="green darken-1" text @click="toggleDialog(markers[0])">
            Close
          </v-btn>
          <v-spacer></v-spacer>
      <v-btn color="success" class="px-5" @click="testMarker()"
        >Confirm Edit Address Address</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import * as fb from '../../firebase'
import loading from "../../mapsLoader";
import Autocomplete from "../shared/Autocomplete.vue";

export default {
  components: { Autocomplete },

  props: {
    driver: {
      type: Object,
      required: true
    },
    toggleDialog: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      mapLoading: true,
      address: "",
      config: {
        center: { lat: 14.7251902, lng: -17.4596118 },
        zoom: 13,
        disableDefaultUI: true,
      },

      sourceMarkers: [],
      markers: [
        {
          name: "",
          lat: 0,
          lng: 0,
        },
      ],
      addressMarker: null,

      driverAddress: {
        lat: 14.7330113,
        lng: -17.4976233,
        name: "Loman Art House - Art Gallery, Dakar, Senegal",
      },
    };
  },

  created() {
    this.driverAddress = this.driver.address
    loading.then((res) => {
      if (res) {
        this.mapLoading = false;
        setTimeout(() => {
          this.initMap()

          this.address = this.driverAddress.name

          this.markers[0] = this.driverAddress

          this.returnSourceMarker(this.driverAddress)
        }, 1000);
      }
    });
  },

  methods: {

    async testMarker() {
      try {

        const driver = await fb.db.doc(`driver/${this.driver.id}`).update({ address: this.markers[0] })

        this.toggleDialog(this.markers[0])
        
      } catch (error) {
        console.log(error)
      }
    },
 
    returnSourceMarker(marker) {

      if (this.sourceMarkers.length > 0) {
        this.sourceMarkers.splice(0, 1);
        this.sourceMarker.setMap(null);
      }

      setTimeout(() => {
        this.sourceMarker = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map: this.map,
          draggable: true,
        });

        this.sourceMarkers.push(this.sourceMarker);

        new google.maps.event.addListener(
          this.sourceMarker,
          "dragend",
          (evt) => {
            let lat = evt.latLng.lat().toFixed(6);
            let lng = evt.latLng.lng().toFixed(6);

            this.markers[0].lat = lat;
            this.markers[0].lng = lng;
          }
        );
      }, 1000);
    },

    returnLatLng(type, payload) {
      if (type == "Source") {
        this.markers[0] = payload;
        this.address = this.markers[0].name
        this.returnSourceMarker(payload);
      } else {
        this.markers[1] = payload;
        this.returnDestMarker(payload);
      }
    },

    initMap() {
      if (google && google.maps) {
        const mapContainer = document.getElementById("map");
        this.map = new google.maps.Map(mapContainer, this.config);
      }
    },
  },
};
</script>

<style></style>
